import axios from '@/plugins/axios';

export const createConcessionaire = async (concessionaireData) => {
  try {
    const response = await axios.post("/concessionaires", concessionaireData);
    return response.data;
  } catch (error) {
    console.error("Error al crear el concesionario:", error);
    throw error;
  }
};

export const updateConcessionaire = async (concessionaireId, concessionaireData) => {
  try {
    const response = await axios.put(`/concessionaires/${concessionaireId}`, concessionaireData);
    return response.data;
  } catch (error) {
    console.error("Error al actualizar el concesionario:", error);
    throw error;
  }
};

export const checkHabitualist = async (cuit) => {
  try {
    const response = await axios.get('/concessionaires/check-habitualist', {
        params: { cuit: String(cuit) },
    });
    return response.data;
  } catch (error) {
    console.error('Error checking habitualist:', error);
    throw error;
  }
};


export const addSucursal = async (concessionaireId, sucursalData) => {
  try {
    const response = await axios.post(`/concessionaires/${concessionaireId}/add-sucursal`, sucursalData);
    return response.data;
  } catch (error) {
    console.error("Error al agregar sucursal:", error);
    throw error;
  }
};

export const getSucursals = async (concessionaireId) => {
  try {
    const response = await axios.get(`/concessionaires/${concessionaireId}/sucursals`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener las sucursales:", error);
    throw error;
  }
};

export const getConcessionaireDetails = async (concessionaireId) => {
  try {
    const response = await axios.get(`/concessionaires/${concessionaireId}`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener los detalles del concesionario:", error);
    throw error;
  }
};

export const changeCentralHouseStatus = async (concessionaireId, data) => {
  console.log(concessionaireId)
  try {
    const response = await axios.post(
      `/concessionaires/${concessionaireId}/change-central-house-status`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error al cambiar el estado de la casa central del concesionario:",
      error
    );
    throw error;
  }
};


export const changeSucursalStatus = async (concessionaireId, statusData) => {
  try {
    const response = await axios.post(
      `/concessionaires/${concessionaireId}/change-sucursal-status`,
      statusData
    );
    return response.data;
  } catch (error) {
    console.error("Error al cambiar el estado de la sucursal:", error);
    throw error;
  }
};

export const fetchConcessionairesApi = async ({ page, perPage, search, type }) => {
  const url = '/concessionaires'; 
  
  return axios.get(url, { 
    params: {
      page: page,
      perPage: perPage,
      search: search,
      type: type,
    },
    withCredentials: false,
  });
};

export const checkConcessionaire = async (cuit) => {
  try {
    const response = await axios.get("/registrations/check-concessionaire", {
      params: { cuit: String(cuit) },
    });
    return response.data;
  } catch (error) {
    console.error("Error al verificar el concesionario:", error);
    throw error;
  }
};