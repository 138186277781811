import { toRaw } from "vue";

export function buildFormData(form, linkType) {
  const formData = new FormData();

  Object.entries(form).forEach(([key, value]) => {
    switch (key) {
      case "image":
      case "icon":
        if (value instanceof File) {
          formData.append(key, value);
        }
        break;

      case "file":
        if (linkType === "pdf" && (value instanceof File || !value)) {
          formData.append(key, value);
        }else {
          formData.append(key, "");
        }
        break;

      case "files":
        console.log("value",value)
        if (Array.isArray(value)) {
          const rawFiles = toRaw(value);
          rawFiles.forEach((file, index) => {
            if (file instanceof File) {
              formData.append(`files[${index}]`, file);
            } else {
              const jsonBlob = new Blob([JSON.stringify(file)], { type: file.type || "application/octet-stream" });
              const convertedFile = new File([jsonBlob], file.name || `file_${index}`);
              formData.append(`files[${index}]`, convertedFile);
            }
          });
        }
        break;



      case "imagePreview":
      case "iconPreview":
        break;

      default:
        formData.append(key, value);
        break;
    }
  });

  return formData;
}