import axios from '@/plugins/axios';


export const fetchHabitualistApi = async ({ page, perPage, search }) => {
  const url = '/habitualist'; 
  
  return axios.get(url, { 
    params: {
      page: page,
      perPage: perPage,
      search: search,
    },
    withCredentials: false,
  });
};


export const fetchUsers = async ({
  page = 1,
  perPage = 10,
  search = '',
  orderBy = 'id',
  orderByMethod = 'asc',
  status = null,
  userType = 2,
} = {}) => {
  const url = '/users';

  try {
    const response = await axios.get(url, {
      params: {
        page,
        perPage,
        search,
        orderBy,
        orderByMethod,
        status,
        userType,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};


/**
 * Delete a user by ID.
 * @param {number} userId - The ID of the user to delete.
 */
export const deleteUser = async (userId) => {
  const url = `/users/${userId}`;

  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(`Error deleting user with ID ${userId}:`, error);
    throw error;
  }
};



export const createUser = async (userData) => {
  const url = '/concessionaire-users';

  try {
    const response = await axios.post(url, userData);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};


export const updateUser = async (userId, userData) => {
  const url = `/concessionaire-users/${userId}`;

  try {
    const response = await axios.put(url, userData);
    return response.data;
  } catch (error) {
    console.error(`Error updating user with ID ${userId}:`, error);
    throw error;
  }
};