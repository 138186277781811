<template>
  <div
    class="modal fade"
    id="sliderModal"
    tabindex="-1"
    aria-labelledby="sliderModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-custom-width">
      <div class="modal-content">
        <ModalHeader
          :view="view"
          subtitle="Botones"
          modalId="sliderModal"
          @close="closeModal"
        />
        <div class="modal-body">
          <div class="d-flex justify-content-between mb-3">
            <SearchInput v-model="searchTerm" />
            <button class="btn btn-primary" @click="openButtonLinkForm">
              Nuevo
            </button>
          </div>
          <div v-if="loading" class="text-center">Cargando...</div>
          <div v-else>
            <div class="table-responsive" style="padding-bottom: 20px">
              <table
                ref="table"
                class="table table-bordered table-striped table-responsive-stack"
                :id="BUTTON_LINK_MODAL_ID"
              >
              <thead>
  <tr>
    <th rowspan="2">Tipo</th>
    <th rowspan="2">Nombre</th>
    <th rowspan="2">Titulo</th>
    <th rowspan="2">Subtitulo</th>
    <th rowspan="2">Link</th>
    <th rowspan="2">Image</th>
    <th rowspan="2">Habilitado</th>
    <th colspan="2">Socios</th>
    <th rowspan="2">Opciones</th>
  </tr>
  <tr>
    <th>Oculto</th>
    <th>Bloqueado</th>
  </tr>
</thead>

                <tbody>
                  <tr
                    v-for="buttonLink in filteredButtonLink"
                    :key="buttonLink.id"
                  >
                    <td
                      :style="{
                        color: getTypeColor(buttonLink.type),
                      }"
                    >
                      {{ getTypeLabel(buttonLink.type) }}
                    </td>
                    <td data-label="Name">{{ buttonLink.name }}</td>
                    <td
                      v-if="buttonLink.title?.toLowerCase() !== 'no title'"
                      data-label="Title"
                    >
                      {{ buttonLink.title }}
                    </td>
                    <td v-else data-label="Title"></td>
                    <td data-label="Content">{{ buttonLink.content }}</td>
                    <td data-label="Link">{{ buttonLink.link }}</td>
                    <td data-label="Image">
                      <template
                        v-if="
                          buttonLink.image && buttonLink.image.endsWith('.webm')
                        "
                      >
                        <video
                          class="img-thumbnail"
                          style="max-width: 100px; max-height: 100px"
                          controls
                        >
                          <source :src="buttonLink.image" type="video/webm" />
                          Tu navegador no soporta el formato de video.
                        </video>
                      </template>
                      <template v-else-if="buttonLink.image">
                        <img
                          :src="buttonLink.image"
                          alt="Image"
                          class="img-thumbnail"
                          style="max-width: 100px; max-height: 100px"
                        />
                      </template>
                    </td>

                    <td data-label="Estado">
                      <div class="custom-switch">
                        <input
                          type="checkbox"
                          :id="'switch-' + buttonLink.id"
                          :checked="buttonLink.status === 2"
                          @change="
                            (event) =>
                              handleStatusChange(event, buttonLink, 'status')
                          "
                        />
                        <label :for="'switch-' + buttonLink.id"></label>
                      </div>
                    </td>

                    <td data-label="Visible">
                      <div class="custom-switch">
                        <input
                          type="checkbox"
                          :id="'visible-switch-' + buttonLink.id"
                          :checked="buttonLink.privacy === 1"
                          @change="
                            (event) =>
                              handleStatusChange(event, buttonLink, 'privacy')
                          "
                        />
                        <label :for="'visible-switch-' + buttonLink.id"></label>
                      </div>
                    </td>
                    <td data-label="Bloqueado">
                      <div class="custom-switch">
                        <input
                          type="checkbox"
                          :id="'blocked-switch-' + buttonLink.id"
                          :checked="buttonLink.login === 1"
                          @change="
                            (event) =>
                              handleStatusChange(event, buttonLink, 'login')
                          "
                        />
                        <label :for="'blocked-switch-' + buttonLink.id"></label>
                      </div>
                    </td>

                    <td data-label="Opciones">
                      <button
                        class="btn btn-sm btn-outline-primary"
                        @click="editButtonLink(buttonLink)"
                      >
                        Editar
                      </button>
                      <button
                        class="btn btn-sm btn-outline-danger"
                        @click="removeButtonLink(buttonLink.id)"
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <DataPagination
              :current-page="currentPage"
              :last-page="lastPage"
              :has-more-pages="hasMorePages"
              @fetch-views="loadButtonLink"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <ButtonLinkForm
    v-if="showButtonLinkForm"
    :view="view"
    :header="selectedButtonLink"
    :button-link-types="buttonLinkTypes"
    :found-background-color="foundBackgroundColor"
    @close="closeButtonLinkForm"
    @buttonLink-created="loadButtonLink"
    @success="handleSuccess"
    @error="handleError"
    @fetchButtonColor="fetchButtonColorHandler"
  />
</template>

<script>
import { reactive, ref, computed, onMounted } from "vue";
import { Modal } from "bootstrap";
import ModalHeader from "@/components/shared/ModalHeader.vue";
import DataPagination from "@/components/data-display/DataPagination.vue";
import SearchInput from "@/components/data-display/SearchInput.vue";
import userConfirmation from "@/utils/userConfirmation.js";
import {
  fetchButtonLinks,
  deleteButtonLink,
  changeButtonLinkStatus,
} from "@/services/api/buttonLinkService.js";
import { usePagination } from "@/composables/usePagination";
import { useNotification } from "@/utils/notificationUtils";
import ButtonLinkForm from "./ButtonLinkForm.vue";
import Swal from "sweetalert2";

const BUTTON_LINK_MODAL_ID = "sliderModal";

export default {
  components: {
    ModalHeader,
    DataPagination,
    SearchInput,
    ButtonLinkForm,
  },
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const buttonLinks = reactive([]);
    const loading = ref(false);
    const showButtonLinkForm = ref(false);
    const selectedButtonLink = ref(null);
    const searchTerm = ref("");
    const foundBackgroundColor = ref(null);

    const { currentPage, lastPage, hasMorePages } = usePagination();

    const { handleSuccess, handleError } = useNotification();

    const buttonLinkTypes = [
      { value: 1, label: "No Incluir Botón", color: "#ff4d4d" },
      { value: 2, label: "Incluir Botón", color: "#28a745" },
      { value: 3, label: "Banner", color: "#007bff" },
      { value: 4, label: "Banner con Botón", color: "#207bff" },
      { value: 5, label: "Banner Multiple", color: "#ffc107" },
    ];

    const getTypeLabel = (type) => {
      const foundType = buttonLinkTypes.find((t) => t.value === type);
      return foundType ? foundType.label : "Desconocido";
    };

    const getTypeColor = (type) => {
      const foundType = buttonLinkTypes.find((t) => t.value === type);
      return foundType ? foundType.color : "transparent";
    };

    const filteredButtonLink = computed(() => {
      if (!searchTerm.value) return buttonLinks.value;
      return buttonLinks.value.filter(
        (buttonLink) =>
          buttonLink.name
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase()) ||
          buttonLink.title
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase()) ||
          buttonLink.content
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase())
      );
    });

    const loadButtonLink = async (page = 1) => {
      loading.value = true;
      try {
        const response = await fetchButtonLinks(props.view.id, page);
        buttonLinks.value = response;

        console.log("buttonLinks.value", buttonLinks.value);

        currentPage.value = response.current_page || 1;
        lastPage.value = response.last_page || 1;
        hasMorePages.value = currentPage.value < lastPage.value;
      } catch (error) {
        console.error("Error fetching buttonLinks:", error);
      } finally {
        loading.value = false;
      }
    };

    const openButtonLinkForm = () => {
      selectedButtonLink.value = null;
      showButtonLinkForm.value = true;
    };

    const closeButtonLinkForm = () => {
      showButtonLinkForm.value = false;
    };

    const closeModal = () => {
      emit("close");
    };

    const editButtonLink = (buttonLink) => {
      console.log("editButtonLink", buttonLink);
      selectedButtonLink.value = buttonLink;
      showButtonLinkForm.value = true;
    };

    const removeButtonLink = async (headerId) => {
      const confirmed = await userConfirmation(
        "Esta seguro de eliminar el Botón seleccionado"
      );
      if (!confirmed) return;
      try {
        await deleteButtonLink(headerId);
        loadButtonLink(currentPage.value);
      } catch (error) {
        console.error("Error eliminando buttonLink:", error);
      }
    };

    const handleStatusChange = async (event, buttonLink, attribute) => {
      const originalValue = buttonLink[attribute];
      let newValue;

      if (attribute === "status") {
        newValue = event.target.checked ? 2 : 1; // 2 habilitado, 1 deshabilitado
      } else {
        newValue = event.target.checked ? 1 : 0; // 1 habilitado, 0 deshabilitado
      }

      try {
        const result = await Swal.fire({
          title: "¿Estás seguro?",
          text: "Esta acción actualizará el estado del botón.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, actualizar",
          cancelButtonText: "Cancelar",
        });

        if (result.isConfirmed) {
          buttonLink[attribute] = newValue;
          const payload = { [attribute]: newValue };
          await changeButtonLinkStatus(buttonLink.id, payload);

          await Swal.fire({
            title: "Actualizado",
            text: "El estado se actualizó correctamente.",
            icon: "success",
            confirmButtonText: "Aceptar",
          });

          console.log("Estado actualizado con éxito");
        } else {
          event.target.checked =
            originalValue === (attribute === "status" ? 2 : 1);
        }
      } catch (error) {
        console.error("Error actualizando el estado del ButtonLink:", error);

        await Swal.fire({
          title: "Error",
          text: "Ocurrió un error al intentar actualizar el estado.",
          icon: "error",
          confirmButtonText: "Aceptar",
        });

        event.target.checked =
          originalValue === (attribute === "status" ? 2 : 1);
      }
    };

    const fetchButtonColorHandler = (newType, title) => {
      const foundButtonLink = buttonLinks.value.find(
        (buttonLink) =>
          buttonLink.type === newType && buttonLink.title === title
      );

      if (foundButtonLink && foundButtonLink.background_color) {
        foundBackgroundColor.value = foundButtonLink.background_color;
      } else {
        foundBackgroundColor.value = null;
      }

      emit("fetchButtonColor", foundBackgroundColor.value);
    };

    onMounted(async () => {
      loadButtonLink();
      const modalElement = document.getElementById(BUTTON_LINK_MODAL_ID);
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    });

    return {
      handleStatusChange,
      buttonLinks,
      loading,
      showButtonLinkForm,
      selectedButtonLink,
      searchTerm,
      foundBackgroundColor,
      currentPage,
      lastPage,
      hasMorePages,
      filteredButtonLink,
      loadButtonLink,
      removeButtonLink,
      openButtonLinkForm,
      closeButtonLinkForm,
      closeModal,
      editButtonLink,
      handleSuccess,
      handleError,
      buttonLinkTypes,
      getTypeLabel,
      getTypeColor,
      fetchButtonColorHandler,
    };
  },
};
</script>

<style scoped>
.modal-content {
  padding: 20px;
  background-color: #f9f9f9;
}

.modal-custom-width {
  max-width: 85%;
}

.img-thumbnail {
  object-fit: cover;
}

.custom-switch {
  display: inline-block;
  width: 43px;
  height: 28px;
  position: relative;
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-switch label:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  left: 1px;
  bottom: 1px;
  transition: transform 0.3s;
}

.custom-switch input:checked + label {
  background-color: #4caf50;
}

.custom-switch input:checked + label:before {
  transform: translateX(20px);
}

.table-responsive-stack {
  width: 100%;
  table-layout: fixed;
}

.table-responsive-stack th,
.table-responsive-stack td {
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .table-responsive-stack tr {
    display: block;
    border-bottom: 3px solid #ccc;
  }

  .table-responsive-stack th {
    display: none;
  }

  .table-responsive-stack td {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    padding-left: 50%;
    box-sizing: border-box;
  }

  .modal-dialog {
    margin: 0;
    width: 100%;
  }

  .modal-content {
    width: 100%;
  }

  .modal-custom-width {
    max-width: 100%;
  }
}

.table-responsive {
  padding-bottom: 20px;
}

tbody {
  width: 100%;
}

th {
  text-align: center;
  vertical-align: middle;
}

td {
  text-align: center;
  vertical-align: middle;
}

.table-responsive-stack {
  width: 100%;
  table-layout: fixed;
}

.table-responsive-stack th,
.table-responsive-stack td {
  word-wrap: break-word;
  text-align: center;
}
</style>
