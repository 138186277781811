<template>
  <div
    v-if="isOpen"
    class="modal fade show"
    style="display: block; background-color: rgba(0, 0, 0, 0.5)"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content shadow-lg rounded-3 border-0">
        <div
          class="modal-header bg-dark text-light rounded-top py-3 px-4 border-bottom"
        >
          <h4 class="modal-title fw-bold mb-0">Detalles del Registro</h4>
          <button
            type="button"
            class="btn-close btn-close-white"
            aria-label="Cerrar"
            @click="closeModal"
          ></button>
        </div>

        <div class="modal-body p-4">
          <div class="section mb-4">
            <h5 class="section-title bg-secondary text-white py-2 px-3 rounded">
              Información Personal
            </h5>
            <div class="mb-3 row">
              <div class="col-md-6">
                <p>
                  <strong>Nombre:</strong> {{ registration?.name || "N/A" }}
                </p>
                <p>
                  <strong>Apellido:</strong>
                  {{ registration?.lastname || "N/A" }}
                </p>
                <p><strong>DNI:</strong> {{ registration?.dni || "N/A" }}</p>
              </div>
              <div class="col-md-6">
                <p>
                  <strong>Email:</strong> {{ registration?.email || "N/A" }}
                </p>
                <p>
                  <strong>Teléfono Personal:</strong>
                  {{ registration?.personal_phone || "N/A" }}
                </p>
                <p>
                  <strong>Teléfono de Negocios:</strong>
                  {{ registration?.business_phone || "N/A" }}
                </p>
              </div>
            </div>
          </div>

          <div class="section mb-4">
            <h5 class="section-title bg-secondary text-white py-2 px-3 rounded">
              Concesionaria
            </h5>
            <div class="mb-3 row">
              <div class="col-md-6">
                <p>
                  <strong>Razón Social:</strong>
                  {{ registration?.concessionaire?.business_name || "N/A" }}
                </p>
                <p>
                  <strong>CUIT:</strong>
                  {{ registration?.concessionaire?.cuit || "N/A" }}
                </p>
              </div>
              <div class="col-md-6">
                <p>
                  <strong>Código de Cliente:</strong>
                  {{ registration?.concessionaire?.client_code || "N/A" }}
                </p>
                <p>
                  <strong>Número de Socio:</strong>
                  {{ registration?.concessionaire?.member_number || "N/A" }}
                </p>
              </div>
            </div>
          </div>

          <div class="section mb-4">
            <h5 class="section-title bg-secondary text-white py-2 px-3 rounded">
              Cargos
            </h5>
            <div class="d-flex justify-content-center">
              <button
                class="btn d-flex justify-content-center align-items-center gap-2 px-3 py-2 text-white"
                @click="openPositionsModal"
                style="background-color: #4a90e2; border: none"
              >
                <i class="fas fa-briefcase"></i> Ver Cargos
              </button>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success me-2"
            @click="approveRegistration"
          >
            <i class="fas fa-check-circle"></i> Aceptar
          </button>
          <button
            type="button"
            class="btn btn-danger me-2"
            @click="rejectRegistration"
          >
            <i class="fas fa-times-circle"></i> Rechazar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs } from "vue";

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    registration: {
      type: Object,
      required: true,
    },
  },
  emits: ["close", "open-positions-modal", "approve", "reject"],
  setup(props, { emit }) {
    const { registration } = toRefs(props);

    const closeModal = () => {
      emit("close");
    };

    const openPositionsModal = () => {
      emit("open-positions-modal", registration.value.positions);
    };

    const approveRegistration = async () => {
      try {
        await emitAsync("approve", registration.value.id);
        emit("close"); 
      } catch (error) {
        console.error("Error approving registration:", error);
      }
    };

    const rejectRegistration = async () => {
      try {
        await emitAsync("reject", registration.value.id); 
        emit("close");
      } catch (error) {
        console.error("Error rejecting registration:", error);
      }
    };

    const emitAsync = (event, ...args) => {
      return new Promise((resolve, reject) => {
        try {
          emit(event, ...args, resolve); 
        } catch (error) {
          reject(error);
        }
      });
    };


    return {
      closeModal,
      openPositionsModal,
      approveRegistration,
      rejectRegistration,
    };
  },
});
</script>

<style scoped>
.modal {
  z-index: 1050;
}
.modal-header {
  background-color: #007bff;
  color: #fff;
}
.modal-content {
  border-radius: 10px;
}
.row {
  margin-bottom: 1rem;
}

.btn {
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #3b78c0;
}
</style>
