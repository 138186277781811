import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { setupResponseInterceptor } from './authInterceptor';

const app = createApp({});
app.use(VueAxios, axios);

const instance = axios.create({
  baseURL: process.env.VUE_APP_ACARA_WEB_BACKEND_URL ?? 'http://localhost:8000/api/v1',
  withCredentials: false,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

instance.interceptors.request.use(
  (config) => {
    console.log("localStorage.getItem('user_access_token')",localStorage.getItem('user_access_token'))
    const token = localStorage.getItem('user_access_token');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

setupResponseInterceptor(instance);

export default instance;