export function filterTable(tableData, searchTerm) {
  console.log(tableData)
  try {
    if (!searchTerm) return tableData;

    const lowerCaseSearch = searchTerm.toLowerCase();

    return tableData.filter((entity) => {
      return Object.values(entity).some((value) => {
        if (value && typeof value === 'string') {
          return value.toLowerCase().includes(lowerCaseSearch);
        }
        return false;
      });
    });
  } catch (error) {
    console.error("Error filtering table data:", error);
    return tableData;
  }
}

  