<template>
  <div
    class="modal fade"
    id="sectionFormModal"
    tabindex="-1"
    aria-labelledby="sliderFormModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sliderFormModalLabel">
            {{ isEditing ? "Editar Section" : "Nueva Section" }}
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="closeForm"
            :disabled="isLoading"
          ></button>
        </div>
        <div class="modal-body">
          <!-- Step 1: Bloque -->
          <div v-if="currentStep === 1">
            <form @submit.prevent="nextStep">
              <ToggleField
                label="Nombre"
                id="block_name"
                v-model="blockName"
                type="input"
              />
              <!-- <div class="mb-3">
                <label for="block_name" class="form-label"
                  >Nombre del bloque</label
                >
                <input
                  type="text"
                  id="block_name"
                  class="form-control"
                  v-model="blockName"
                  required
                />
              </div> -->
              <ToggleField
                label="Título"
                id="block_title"
                v-model="blockTitle"
                type="input"
              />
              <!-- <div class="mb-3">
                <label for="block_title" class="form-label"
                  >Título del bloque</label
                >
                <input
                  type="text"
                  id="block_title"
                  class="form-control"
                  v-model="blockTitle"
                  required
                />
              </div> -->
              <div class="mb-3">
                <label for="block_order" class="form-label"
                  >Orden del bloque</label
                >
                <input
                  type="number"
                  id="block_order"
                  class="form-control"
                  v-model="blockOrder"
                  min="1"
                  required
                />
              </div>
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="!isFirstStepValid || isLoading"
              >
                Siguiente
              </button>
            </form>
          </div>

          <!-- Step 2: Plantilla -->
          <div v-if="currentStep === 2">
            <form @submit.prevent="nextStep">
              <div class="mb-3">
                <label class="form-label">Selecciona una plantilla</label>
                <div class="d-flex justify-content-evenly m-5">
                  <div
                    v-for="(template, index) in templates"
                    :key="index"
                    @click="selectTemplate(template)"
                    :class="{
                      'template-selection selected':
                        selectedTemplate &&
                        selectedTemplate.image === template.image,
                      'template-selection':
                        !selectedTemplate ||
                        selectedTemplate.image !== template.image,
                    }"
                  >
                    <img
                      :src="getTemplateImagePath(template)"
                      :alt="'Plantilla ' + (index + 1)"
                      class="img-thumbnail"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <!-- Botón "Atrás" para volver al primer paso -->
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="prevStep"
                  :disabled="isLoading"
                >
                  Anterior
                </button>

                <!-- Botón "Siguiente" -->
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="nextStep"
                  :disabled="!selectedTemplate || isLoading"
                >
                  Siguiente
                </button>
              </div>
            </form>
          </div>

          <StepForm
            v-for="step in steps"
            :key="step"
            :step="step"
            :current-step="currentStep"
            :form="getForm(step)"
            :selected-option="getSelectedOption(step)"
            :get-template-path="getTemplatePath"
            :selectedTemplate="selectedTemplate"
            :is-editing="isEditing"
            :steps="steps"
            @prev-step="prevStep"
            @next-step="nextStep"
            @update="handleUpdate"
            :disabled="isLoading"
            @validateStep="handleValidation"
          />

          <div v-if="selectedTemplate && currentStep === steps.length + 2">
            <button
              type="button"
              class="btn btn-secondary"
              @click="prevStep"
              :disabled="isLoading"
            >
              Anterior
            </button>
            {{ isStepValid }}
            <button
              type="button"
              class="btn btn-primary"
              @click="submitForm"
              :disabled="isSaveDisabled || isLoading"
            >
              {{
                isLoading
                  ? "Guardando..."
                  : isEditing
                  ? "Actualizar"
                  : "Guardar"
              }}
            </button>
            <div
              v-if="isLoading"
              class="spinner-border text-primary"
              role="status"
            >
              <span class="visually-hidden">Cargando...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from "vue";
import { Modal } from "bootstrap";
import { createBlock, updateBlock } from "@/services/api/blockService.js";
import StepForm from "@/components/block/StepForm.vue";
import Quill from "quill";
import userConfirmation from "@/utils/userConfirmation.js";
import ToggleField from "@/components/shared/ToggleField.vue";

const FORM_MODAL_ID = "sectionFormModal";

export default {
  components: {
    StepForm,
    ToggleField,
  },
  props: {
    view: {
      type: Object,
      required: true,
    },
    header: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false);
    const currentStep = ref(1);
    const selectedTemplate = ref(null);
    const blockName = ref("");
    const blockOrder = ref(1);
    const blockTitle = ref("");
    const blockId = ref(null);
    const isLoading = ref(false);
    const isStepValid = ref(false);

    const selectedOptionStep3 = ref("image");
    const selectedOptionStep4 = ref("image");
    const selectedOptionStep5 = ref("image");

    const createFormStep = () => ({
      content: "",
      image: null,
      imagePreview: "",
      view_id: props.view?.id || "",
      alignment: "",
      order: "",
      type: "",
      background_color: "",
    });

    const formStep3 = ref(createFormStep());
    const formStep4 = ref(createFormStep());
    const formStep5 = ref(createFormStep());

    const templates = ref([
      {
        image: "plantilla_1.png",
        number: 3,
        id: 1,
        type_template_1: 1,
        type_template_2: 2,
        type_template_3: 2,
      }, //larga-corta-corta
      {
        image: "plantilla_2.png",
        number: 3,
        id: 2,
        type_template_1: 2,
        type_template_2: 2,
        type_template_3: 1,
      }, //corta-corta-larga
      {
        image: "plantilla_3.png",
        number: 2,
        id: 3,
        type_template_1: 2,
        type_template_2: 2,
      }, //corta-corta
      {
        image: "plantilla_4.png",
        number: 1,
        id: 4,
        type_template_1: 1,
      }, //corta
    ]);

    const populateFormFields = (header) => {
      if (!header) return;

      blockName.value = header.name || "";
      blockTitle.value = header.title || "";
      blockOrder.value = header.order || 1;
      blockId.value = header.id || null;

      const sections = header.sections.sort((a, b) => a.order - b.order);
      const forms = [formStep3, formStep4, formStep5];

      sections.forEach((section, index) => {
        if (forms[index]) {
          forms[index].value = { ...createFormStep(), ...section };
        }
      });

      selectedTemplate.value = templates.value.find(
        (template) => template.id === header.block_template.id
      );
    };

    const selectTemplate = (template) => {
      selectedTemplate.value = template;

      const steps = [
        { form: formStep3, type: template.type_template_1, order: 1 },
        { form: formStep4, type: template.type_template_2, order: 2 },
        { form: formStep5, type: template.type_template_3, order: 3 },
      ];

      steps.forEach((step, index) => {
        if (template.number > index) {
          step.form.value.order = step.order;
          step.form.value.type = step.type;
          step.form.value.alignment = 2;
        }
      });
    };

    const isFirstStepValid = computed(() => {
      return Number.isInteger(blockOrder.value) && blockOrder.value > 0;
    });

    const steps = computed(() => {
      return selectedTemplate.value
        ? Array.from({ length: selectedTemplate.value.number }, (_, i) => i + 3)
        : [];
    });

    const forms = computed(() => {
      const activeForms = [formStep3];
      const templateNumber = selectedTemplate.value?.number || 0;
      const sectionsLength = props.header?.sections.length || 0;

      if (templateNumber > 1 || sectionsLength > 1) {
        activeForms.push(formStep4);
      }

      if (templateNumber > 2 || sectionsLength > 2) {
        activeForms.push(formStep5);
      }

      return activeForms;
    });

    const isSaveDisabled = computed(() => {
      return !isStepValid.value || isLoading.value;
    });

    watch(
      () => props.header,
      (newHeader) => {
        if (newHeader) {
          isEditing.value = true;
          populateFormFields(newHeader);
        }
      },
      { immediate: true }
    );

    const getTemplateImagePath = (template) => {
      return require(`@/assets/images/${template.image}`);
    };

    const getForm = (step) => {
      return step === 3
        ? formStep3.value
        : step === 4
        ? formStep4.value
        : formStep5.value;
    };

    const getTemplatePath = (number) => {
      if (!selectedTemplate.value) return "";
      const baseName = selectedTemplate.value.image.split(".png")[0];
      return require(`@/assets/images/${baseName}_${number}.png`);
    };

    const getSelectedOption = (step) => {
      return step === 3
        ? selectedOptionStep3.value
        : step === 4
        ? selectedOptionStep4.value
        : selectedOptionStep5.value;
    };

    const handleUpdate = ({ key, value, background_color, step }) => {
      const steps = {
        3: formStep3,
        4: formStep4,
        5: formStep5,
      };

      const currentStep = steps[step]?.value;

      if (currentStep) {
        if (key === "content" && value) {
          const quill = new Quill(document.createElement("div"));
          quill.setContents(value);

          currentStep.content = quill.root.innerHTML;
          currentStep.background_color = background_color;
          currentStep.image = null;
        } else if (key === "image" && value) {
          currentStep.image = value;
          currentStep.content = "";
        }
      }
    };

    const handleValidation = ({ step, isValid }) => {
      console.log("step",step)
      if ([3, 4, 5].includes(step)) {
        isStepValid.value = isValid;
      }
    };

    const appendFormData = (formData, form, index) => {
      console.log("form", form);
      formData.append(`sections[${index}][id]`, form.id);
      formData.append(`sections[${index}][content]`, form.content);
      if (form.image) {
        formData.append(`sections[${index}][image]`, form.image);
      }
      formData.append(`sections[${index}][order]`, form.order || "");
      formData.append(`sections[${index}][type]`, form.type || "");
      formData.append(`sections[${index}][alignment]`, form.alignment || "");
      formData.append(
        `sections[${index}][background_color]`,
        form.background_color
      );
    };

    const getLimitedForms = () => {
      const maxSections = selectedTemplate.value.number;
      return forms.value.slice(0, maxSections);
    };

    const submitForm = async () => {
      console.log("createFormStep", createFormStep);
      isLoading.value = true;

      try {
        const formData = new FormData();
        formData.append("block_name", blockName.value);
        formData.append("block_title", blockTitle.value);
        formData.append("block_order", blockOrder.value);
        formData.append("block_id", blockId.value);
        formData.append("block_status", 1);
        formData.append("block_template", parseInt(selectedTemplate.value.id));
        formData.append("view_id", props.view?.id || "");
        console.log("selectedTemplate.value", selectedTemplate.value);

        const limitedForms = getLimitedForms();

        limitedForms.forEach((form, index) => {
          appendFormData(formData, form.value, index);
        });

        const editOrCreate = isEditing.value ? "editar" : "crear";
        const confirmed = await userConfirmation(
          `¿Está seguro de ${editOrCreate} el bloque?`
        );
        if (!confirmed) throw new Error("Acción cancelada por el usuario.");

        if (isEditing.value) {
          await updateBlock(props.header.id, formData);
          emit("success", "Bloque actualizado exitosamente.");
        } else {
          await createBlock(formData);
          emit("success", "Bloque creado exitosamente.");
        }

        emit("slider-created");
        closeForm();
      } catch (error) {
        emit(
          "error",
          error.response?.data?.message || "Error al procesar la solicitud."
        );
      } finally {
        isLoading.value = false;
      }
    };

    const nextStep = () => {
      if (currentStep.value <= steps.value.length + 1) currentStep.value += 1;
    };

    const prevStep = () => {
      if (currentStep.value > 1) currentStep.value -= 1;
    };

    const closeForm = () => {
      emit("close");
      const modalElement = document.getElementById(FORM_MODAL_ID);
      const modalInstance = Modal.getInstance(modalElement);
      modalInstance.hide();
    };

    onMounted(() => {
      const modalElement = document.getElementById(FORM_MODAL_ID);
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    });

    return {
      isEditing,
      currentStep,
      selectedTemplate,
      templates,
      steps,
      blockName,
      blockTitle,
      blockOrder,
      blockId,
      isFirstStepValid,
      formStep3,
      formStep4,
      formStep5,
      selectedOptionStep3,
      selectedOptionStep4,
      selectedOptionStep5,
      handleUpdate,
      handleValidation,
      submitForm,
      nextStep,
      prevStep,
      isSaveDisabled,
      closeForm,
      isLoading,
      getTemplateImagePath,
      getForm,
      getTemplatePath,
      getSelectedOption,
      selectTemplate,
    };
  },
};
</script>

<style scoped>
.modal-content {
  padding: 20px;
  background-color: #f9f9f9;
}

.template-selection {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.template-selection.selected {
  border: 2px solid #007bff;
  transform: scale(1.05);
}

.template-selection img {
  max-width: 245px;
  max-height: 245px;
  display: block;
  margin: auto;
}

.spinner-border {
  display: block;
  margin: 10px auto;
}
</style>
