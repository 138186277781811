import Swal from "sweetalert2";
import { useRouter } from "vue-router";

export function useGoBack() {
  const router = useRouter();


  const goBack = (routeName = "ConcessionaireSubMenu", routeParams = { subMenu: "casa-central" }, showConfirmation = true) => {
    try {
      JSON.stringify(routeParams); 

      if (showConfirmation) {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "Se perderán los cambios realizados si sales de esta página.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sí, salir",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            router.push({
              name: routeName,
              params: routeParams,
            });
          }
        });
      } else {
        router.push({
          name: routeName,
          params: routeParams,
        });
      }
    } catch (error) {
      console.error("Error en los parámetros de navegación:", error);
      Swal.fire({
        icon: "error",
        title: "Error en la navegación",
        text: "No se pudo navegar debido a un error interno.",
      });
    }
  };

  return { goBack };
}
