<template>
  <div v-if="isOpen" class="modal d-block" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ showSelection ? "Seleccionar Sucursal" : "Sucursales" }}
          </h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <div class="search-bar mb-3">
            <input
              type="text"
              v-model="searchTerm"
              class="form-control"
              placeholder="Buscar sucursal..."
            />
          </div>
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>id</th>
                <th>CUIT</th>
                <th>Razón Social</th>
                <th>Provincia</th>
                <th>Marca</th>
                <th>Web</th>
                <th>Estado</th>
                <th v-if="showSelection">Seleccionar</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="sucursal in filteredSucursals"
                :key="sucursal.id"
                :class="{ 'row-disabled': sucursal.status === 0 }"
              >
                <td>{{ sucursal.id }}</td>
                <td>{{ sucursal.cuit }}</td>
                <td>{{ sucursal.business_name }}</td>
                <td>{{ sucursal.province_name }}</td>
                <td>{{ sucursal.brand_name }}</td>
                <td>
                  <a :href="sucursal.website" target="_blank">{{
                    sucursal.website || "N/A"
                  }}</a>
                </td>
                <td
                  :class="{
                    'status-enabled': sucursal.status === 1,
                    'status-disabled': sucursal.status === 0,
                  }"
                >
                  {{
                    sucursal.status === 1
                      ? "Habilitado"
                      : sucursal.status === 0
                      ? "Bloqueado"
                      : "Desconocido"
                  }}
                </td>
                <td v-if="showSelection">
                  <input
                    type="radio"
                    name="selectedSucursal"
                    v-model="selectedSucursal"
                    :value="sucursal.id"
                    :disabled="sucursal.status === 0"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="showSelection" class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="!selectedSucursal"
            @click="confirmSelection"
          >
            Seleccionar Sucursal
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import Swal from "sweetalert2";

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    sucursals: {
      type: Array,
      required: true,
    },
    showSelection: {
    type: Boolean,
    default: true,
  },
  },
  setup(props, { emit }) {
    const searchTerm = ref("");
    const selectedSucursal = ref(null);

    const filteredSucursals = computed(() =>
      props.sucursals.filter((sucursal) =>
        sucursal.business_name
          .toLowerCase()
          .includes(searchTerm.value.toLowerCase())
      )
    );

    const closeModal = () => {
      emit("close");
    };

    const confirmSelection = async () => {
      const selectedSucursalData = props.sucursals.find(
        (sucursal) => sucursal.id === selectedSucursal.value
      );

      if (!selectedSucursalData) {
        console.error("No se ha seleccionado ninguna sucursal.");
        return;
      }

      const result = await Swal.fire({
        icon: "warning",
        title: "¡ATENCIÓN!",
        html: `Se seleccionará la sucursal <strong>${selectedSucursalData.business_name}</strong> como la nueva casa central.<br><br>¿Desea continuar?`,
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#007BFF",
        cancelButtonColor: "#6c757d",
      });

      if (result.isConfirmed) {
        emit("success", {
          id: selectedSucursalData.id,
          status: selectedSucursalData.status,
        });
      }
    };

    return {
      searchTerm,
      filteredSucursals,
      closeModal,
      confirmSelection,
      selectedSucursal,
    };
  },
};
</script>

<style scoped>
.modal-dialog.modal-xl {
  max-width: 80%;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.table-striped {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.search-bar input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.status-enabled {
  color: green;
  font-weight: bold;
}

.status-disabled {
  color: red;
  font-weight: bold;
}

.row-disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
