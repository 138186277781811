export const ConcessionaireTypes = {
  1: "Casa Central",
  2: "Sucursal",
};

export const getConcessionaireTypeLabel = (type) =>
  ConcessionaireTypes[type] || "Desconocido";

const ConcessionaireTypeValues = Object.entries(ConcessionaireTypes).reduce(
  (acc, [key, value]) => {
    acc[value.toLowerCase()] = Number(key);
    acc[value.toLowerCase().replace(/\s+/g, "-")] = Number(key); 
    return acc;
  },
  {}
);

export const getConcessionaireTypeValue = (label) =>
  ConcessionaireTypeValues[label.toLowerCase()] || null;
