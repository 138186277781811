import axios from "@/plugins/axios";

const token = localStorage.getItem('user_access_token');
const headers = {
  Authorization: `Bearer ${token}`,
};

export const fetchViewsApi = async (params) => {
  try {
    console.log("params 1",params)
    const response = await axios.get("/views", { params });
    console.log("response",response)
    return response.data;
  } catch (error) {
    throw new Error("Error fetching views: " + error.message);
  }
};


export const deleteView = async (url) => {
  try {
    await axios.delete(`/views/${url}`, { headers });
  } catch (error) {
    throw new Error('Error deleting view: ' + error.message);
  }
};

export const createView = async (viewData) => {
  try {
    const response = await axios.post('/views', viewData, { headers });
    return response.data;
  } catch (error) {
    throw new Error('Error creating view: ' + error.message);
  }
};

export const updateView = async (currentUrl, viewData) => {
  try {
    const response = await axios.put(`/views/${currentUrl}`, viewData, { headers });
    return response.data;
  } catch (error) {
    throw new Error('Error updating view: ' + error.message);
  }
};
