import { ref } from "vue";

export const useFormValidation = () => {
  const formErrors = ref({});

  const validateConcessionaireForm = (formData) => {
    console.log("formData",formData)
    Object.keys(formErrors.value).forEach((key) => delete formErrors.value[key]);

    // Validaciones
    if (!formData.member_number) {
      formErrors.value.member_number = "El número de socio es obligatorio.";
    }
    if (!formData.business_name) {
      formErrors.value.business_name = "La razón social es obligatoria.";
    }
    if (!formData.province_id) {
      formErrors.value.province_id = "La provincia es obligatoria.";
    }
    if (!formData.locality_id) {
      formErrors.value.locality_id = "La localidad es obligatoria.";
    }
    if (!formData.regional_id) {
      formErrors.value.regional_id = "La regional es obligatoria.";
    }
    if (!formData.street_name) {
      formErrors.value.street_name = "La dirección es obligatoria.";
    }
    if (!formData.street_number) {
      formErrors.value.street_number = "El número es obligatorio.";
    }
    if (!formData.postal_code) {
      formErrors.value.postal_code = "El código postal es obligatorio.";
    }
    if (!formData.phone) {
      formErrors.value.phone = "El teléfono es obligatorio.";
    }
    if (!formData.email) {
      formErrors.value.email = "El correo electrónico es obligatorio.";
    }
    if (!formData.vehicle_type) {
      formErrors.value.vehicle_type = "El tipo de vehículo es obligatorio.";
    }
    if (!formData.brand_id) {
      formErrors.value.brand_id = "La marca es obligatoria.";
    }

    return Object.keys(formErrors.value).length === 0;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email) {
      formErrors.value.email = "El correo electrónico es obligatorio.";
      return false;
    } else if (!emailRegex.test(email)) {
      formErrors.value.email = "Formato de correo electrónico inválido.";
      return false;
    } else {
      formErrors.value.email = "";
      return true;
    }
  };

  const validateStreetNumber = (streetNumber) => {
    const streetNumberRegex = /^[0-9]*$/;
    if (!streetNumber) {
      formErrors.value.street_number = "El número es obligatorio.";
      return "";
    } else if (!streetNumberRegex.test(streetNumber)) {
      formErrors.value.street_number =
        "El número de calle debe contener solo números.";
      return streetNumber.replace(/\D/g, "");
    } else {
      formErrors.value.street_number = "";
      return streetNumber;
    }
  };

  return {
    formErrors,
    validateConcessionaireForm,
    validateEmail,
    validateStreetNumber,
  };
};