import axios from '@/plugins/axios';

/**
 * Fetch registrations with optional status filter
 * @param {number|null} status - Status to filter registrations (optional).
 * @param {number} page - Page number for pagination (default is 1).
 * @returns {Promise<Array>} - Returns a promise resolving to the list of registrations.
 */
export const fetchRegistrations = async ({
    search = "",
    orderBy = "name",
    orderByMethod = "asc",
    perPage = 10,
    status = 0,
    page = 1,
} = {}) => {
    try {
        const response = await axios.get('/registrations', {
            params: { search, orderBy, orderByMethod, perPage, status, page },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching registrations:', error);
        throw error;
    }
};



/**
 * Approve a registration by ID
 * @param {number} registrationId - The ID of the registration to approve.
 * @returns {Promise<Object>} - Returns the approved registration data.
 */
export const approveRegistration = async (registrationId) => {
    try {
        const response = await axios.put(`/registrations/${registrationId}/approve`);
        return response.data;
    } catch (error) {
        console.error('Error approving registration:', error);
        throw error;
    }
};

/**
 * Reject a registration by ID
 * @param {number} registrationId - The ID of the registration to reject.
 * @returns {Promise<Object>} - Returns the rejected registration data.
 */
export const rejectRegistration = async (registrationId) => {
    try {
        const response = await axios.put(`/registrations/${registrationId}/reject`);
        return response.data;
    } catch (error) {
        console.error('Error rejecting registration:', error);
        throw error;
    }
};
