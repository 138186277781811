import { reactive } from "vue";

export default function useValidations() {
  const errors = reactive({});

  /**
   * Validar campos individuales en el formulario
   * @param {Object} formData - Los datos del formulario
   * @returns {Boolean} - Indica si todos los campos son válidos
   */
  const validateFields = (formData) => {
    let isValid = true;

    if (!formData.dni) {
      errors.dni = "El DNI es obligatorio.";
      isValid = false;
    } else {
      errors.dni = null;
    }

    if (!formData.concessionaire_id || formData.concessionaire_id === null) {
      errors.concessionaire_id = "Debe seleccionar un concesionario.";
      isValid = false;
    } else {
      errors.concessionaire_id = null;
    }

    if (!formData.name || formData.name.trim() === "") {
      errors.name = "El nombre es obligatorio.";
      isValid = false;
    } else {
      errors.name = null;
    }

    if (!formData.lastname || formData.lastname.trim() === "") {
      errors.lastname = "El apellido es obligatorio.";
      isValid = false;
    } else {
      errors.lastname = null;
    }

    if (!formData.email || !isValidEmail(formData.email)) {
      errors.email = "Debe ingresar un correo electrónico válido.";
      isValid = false;
    } else {
      errors.email = null;
    }

    if (formData.positions && formData.positions.length === 0) {
      errors.positions = "Debe seleccionar al menos un cargo.";
      isValid = false;
    } else {
      errors.positions = null;
    }

    if (!formData.business_phone || !isValidPhone(formData.business_phone)) {
      errors.business_phone = "Debe ingresar un teléfono comercial válido.";
      isValid = false;
    } else {
      errors.business_phone = null;
    }

    if (!formData.personal_phone || !isValidPhone(formData.personal_phone)) {
      errors.personal_phone = "Debe ingresar un teléfono personal válido.";
      isValid = false;
    } else {
      errors.personal_phone = null;
    }

    if (!formData.cuit || !isValidCUIT(formData.cuit)) {
      errors.cuit = "Debe ingresar un CUIT válido.";
      isValid = false;
    } else {
      errors.cuit = null;
    }

    return isValid;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone) => {
    const phoneRegex = /^[0-9]{8,15}$/; 
    return phoneRegex.test(phone);
  };

  const isValidCUIT = (cuit) => {
    const cuitRegex = /^\d{10,11}$/; 
    return cuitRegex.test(cuit);
  };
  

  const clearError = (field) => {
    if (errors[field]) {
      errors[field] = null;
    }
  };

  return {
    errors,
    validateFields,
    clearError,
  };
}
