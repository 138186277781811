<template>
  <transition name="slide">
    <div v-if="loading" class="loading-spinner">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <div v-else class="concessionaire-form-container">
      <div class="header">
        <button @click="goBackToUsers" class="back-button">
          <i class="fas fa-arrow-left"></i>
        </button>
        <h1>Nuevo Usuario</h1>
      </div>

      <!-- Primer paso -->
      <div v-if="currentStep === 1">
        <div class="info-box">
          <i class="fas fa-info-circle info-icon"></i>
          <p>
            Para poder dar de alta a un usuario en el sistema, se lo debe
            asociar con un concesionario existente. Para proceder, se debe
            ingresar el CUIT del concesionario.
          </p>
        </div>
        <div class="form-group">
          <label for="cuit">CUIT de concesionario</label>
          <div class="input-group custom-width">
            <i class="fas fa-search input-icon"></i>
            <input
              v-model="cuit"
              type="text"
              class="form-control"
              id="cuit"
              placeholder="Ingresar CUIT del concesionario"
            />
          </div>
        </div>

        <div class="button-container">
          <button @click="handleContinue(cuit)" class="continue-button">
            CONTINUAR
          </button>
        </div>
      </div>

      <!-- Segundo paso -->
      <UserFormStepTwo
        v-if="currentStep === 2"
        :is-editing="isEditing"
        @prev-step="prevStep"
      />
    </div>
  </transition>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import UserFormStepTwo from "./UserFormStepTwo.vue";
import { checkConcessionaire } from "@/services/api/concessionaireService.js";
import { showLoadingAlert, closeLoadingAlert } from "@/utils/loadingAlert.js";
import { useGoBack } from "@/composables/concessionaire/useGoBack";
import Swal from "sweetalert2";

export default {
  components: {
    UserFormStepTwo,
  },
  setup() {
    const store = useStore();
    const isEditing = ref(false);
    const cuit = ref("");
    const habitualistaData = ref(null);
    const loading = ref(true);
    const currentStep = ref(1);

    const { goBack } = useGoBack();

    const goBackToUsers = () => {
      goBack("AdministradorSubMenu", { subMenu: "usuarios" });
    };

    const handleContinue = async (cuitValue) => {
      isEditing.value = false;
      if (!cuit.value) {
        await Swal.fire({
          icon: "warning",
          title: "Campo requerido",
          text: "Por favor, ingrese el CUIT de Concesionario antes de continuar.",
        });
        return;
      }

      showLoadingAlert();
      try {
        const result = await checkConcessionaire(cuitValue);

         if (result && typeof result === "object") {
          store.commit("setConcessionaireData", result);
          nextStep();
        }
      } catch (error) {
        let errorMessage = "Ha ocurrido un error inesperado.";

        if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else if (
          Array.isArray(error.response?.data) &&
          error.response.data.length > 0
        ) {
          errorMessage = error.response.data[0];
        }

        console.error("Error en checkConcessionaire:", errorMessage);

        closeLoadingAlert();

        await Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
        });
      } finally {
        closeLoadingAlert();
      }
    };

    const nextStep = () => {
      currentStep.value += 1;
    };

    const prevStep = () => {
      currentStep.value -= 1;
    };

    onMounted(() => {
      setTimeout(() => {
        loading.value = false;
      }, 300);
    });

    return {
      cuit,
      loading,
      currentStep,
      habitualistaData,
      nextStep,
      prevStep,
      goBack,
      handleContinue,
      goBackToUsers,
    };
  },
};
</script>

<style scoped>
.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 2rem;
  color: #0056b3;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

.concessionaire-form-container {
  margin: 0 auto;
  max-width: 1320px;
  padding: 24px;
  border-radius: 8px;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.back-button {
  background: none;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;
}

.info-box {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 40px 0;
}

.info-icon {
  font-size: 1.5rem;
  color: #555;
  margin-right: 10px;
  flex-shrink: 0;
  width: 30px;
  text-align: center;
}

.info-box p {
  flex: 1;
  margin: 0;
}

.form-group {
  margin-top: 20px;
}

.input-group {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.input-icon {
  position: absolute;
  left: 10px;
  color: #999;
}

.form-control {
  padding-left: 30px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.continue-button {
  background-color: #0056b3;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.back-button {
  margin-right: auto;
  font-size: 1rem;
}

.small-button {
  font-size: 0.875rem;
  padding: 8px 16px;
}

.custom-width {
  width: 32% !important;
}

@media (max-width: 768px) {
  .concessionaire-form-container {
    padding: 16px;
    max-width: 100%;
  }

  .header {
    flex-direction: column;
    text-align: center;
  }

  .info-box {
    flex-direction: column;
    text-align: center;
  }

  .button-container {
    justify-content: center;
  }

  .continue-button,
  .small-button {
    width: 100%;
    max-width: 150px;
    padding: 8px 16px;
    font-size: 0.875rem;
  }

  .custom-width {
    width: 100% !important;
  }

  .form-group {
    width: 100%;
  }
}
</style>
