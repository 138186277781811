<template>
  <div class="row m-0 align-items-center border p-3 rounded">
    <div class="col-12 col-md-8">
      <label :for="id">{{ label }}</label>
      <i
        v-if="helpId"
        class="bi bi-question-circle cursor-pointer"
        @click="$emit('show-help', helpId)"
      ></i>
      <input
        type="file"
        @change="handleFileUpload"
        class="form-control"
        :id="id"
        :accept="accept"
        multiple
      />
      <small class="form-text text-muted">
        {{
          files.length
            ? `${files.length} archivo(s) seleccionado(s)`
            : noFileMessage
        }}
      </small>
    </div>

    <div class="col-12 mt-3">
      <ul class="list-group">
        <li
          v-for="(file, index) in files"
          :key="index"
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          <div>
            <a
              v-if="file.url"
              :href="file.url"
              target="_blank"
              rel="noopener noreferrer"
              class="text-decoration-none"
            >
              {{ file.name }}
            </a>
            <span v-else>{{ file.name }}</span>
            <span
              v-if="file.type === 'application/pdf'"
              class="badge bg-danger ms-2"
            >
              PDF
            </span>
          </div>
          <button class="btn btn-sm btn-danger" @click="removeFile(index)">
            Eliminar
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import Swal from "sweetalert2";

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    noFileMessage: {
      type: String,
      default: "No se ha seleccionado ningún archivo",
    },
    accept: {
      type: String,
      default: "",
    },
    helpId: {
      type: String,
      default: null,
    },
    initialFiles: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["file-change", "file-removed"],
  setup(props, { emit }) {
    const files = ref([...props.initialFiles]);
    console.log("props.initialFiles",props.initialFiles)
    // Watch for changes in initialFiles
    watch(
      () => props.initialFiles,
      (newFiles) => {
        console.log("props.initialFiles newFiles",newFiles)
        files.value = [...newFiles];
      },
      { immediate: true }
    );

    const handleFileUpload = (event) => {
      const selectedFiles = Array.from(event.target.files);
      const allowedTypes = props.accept.split(",").map((type) => type.trim());

      const validFiles = selectedFiles.filter((file) =>
        allowedTypes.some((type) => {
          if (type === "image/*" && file.type.startsWith("image/")) {
            return true;
          }
          return file.type === type;
        })
      );

      if (validFiles.length !== selectedFiles.length) {
        Swal.fire({
          icon: "error",
          title: "Archivos no permitidos",
          text: `Algunos archivos no cumplen con los tipos permitidos: ${props.accept}.`,
          confirmButtonText: "Aceptar",
        });
        return;
      }

      files.value.push(...validFiles);
      emit("file-change", files.value);
      event.target.value = "";
    };

    const removeFile = (index) => {
      const removedFile = files.value.splice(index, 1)[0];
      emit("file-change", files.value);

      if (removedFile.url) {
        emit("file-removed", removedFile);
      }
    };

    return {
      files,
      handleFileUpload,
      removeFile,
    };
  },
};
</script>

<style scoped>
.cursor-pointer {
  margin-left: 5px;
  cursor: pointer;
}
.list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
